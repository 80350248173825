import React from "react";
import { Routes, Route } from "react-router-dom";
import Pricing from "./components/Pricing";
import Signup from "./components/Signup";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Login from "./components/Login";
import Inquire from "./components/Inquire";
import Security from "./components/Security";
import Topbar from "./components/Topbar";
import Registration from "./components/Registration";
import Silver from "./components/Silver";
import Secure from "./components/Secure";

function App() {
  return (
    <div className="App">
      <Topbar />
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/pricing" element={<Pricing />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/inquire" element={<Inquire />} />
        <Route exact path="/secure" element={<Secure />} /> 
        <Route exact path="/register" element={<Registration />} />
        <Route exact path="/silver" element={<Silver />} />
      </Routes>
    </div>
  );
}

export default App;
