import React from "react";
import bgimg from "../assets/avatarimg.jpg";
import tick from "../assets/green-tick.gif";
import { WiDirectionLeft } from "react-icons/wi";
import { Link } from "react-router-dom";

const Silver = () => {
  return (
    <div className="w-full h-screen flex">
      <div className="grid grid-cols-1 rounded-xl md:grid-cols-2 m-auto lg:w-[1300px] lg:h-[650px] shadow-lg shadow-gray-600 sm:max-w-[1000px] xs:w-[350px] xs:rounded-xl ip:w-[750px] ip:mt-20 sa:w-[300px] sa:p-4 sa:mt-20">
        <div className="lg:mt-20 hidden md:block ip:mr-[10%]">
          <h1 className="text-3xl font-sans ml-20 lg:mt-10 ip:mt-20">
            Task Organizer <span className="text-gray-400">Silver</span>
          </h1>
          <div className="flex mt-20 ml-20">
            <img
              className="lg:w-[100px] lg:h-[100px] ip:w-[100px]"
              src={bgimg}
              alt="/"
            />
            <h2 className="lg:mt-10 lg:ml-5 lg:text-xl font-bold font-sans ip:mt-10 ip:ml-5">
              Our Client Says
            </h2>
          </div>
          <p className="lg:p-8 text-gray-500 ip:p-4 font-sans">
            "My long time search for user friendly and cost effective Office
            management software ends with starting the usage of ZTA's Task
            management software. I highly recommend the software since I derive
            the benefits of advance planning, timely execution, work monitoring,
            client satisfaction and avoidance of late fees and penalties due to
            usage of software. Best wishes and kudos to ZTA team for
            introduction of this excellent software."
          </p>
        </div>
        <div className="lg:p-4 flex flex-col justify-around xs:p-4">
          <div>
            <h2 className="font-sans lg:text-3xl lg:mt-5 lg:mr-20 text-start font-bold mb-8 text-gray-600 xs:text-2xl xs:text-center ip:mr-8 ">
              Quick Registration for Trial
            </h2>
            <h3 className="text-gray-500 xs:text-xl font-sans">
              Great! You're just a few steps away from using Task Organizer.
            </h3>
          </div>
          <div className="flex">
            <img src={tick} alt="tick"></img>
            <p className="lg:mt-5 font-sans text-gray-500">
              Your Trial details are sent to your email. You can start the trial
              using the login details given in email
            </p>
          </div>
          <p className="text-bold lg:text-2xl font-sans">
            Please check your email and Login to Task Organizer Trial
          </p>
          <div className="flex">
            <WiDirectionLeft size="3rem" className="text-indigo-600" />
            <p className="lg:mt-1 lg:text-3xl text-indigo-600 font-sans sa:mt-3">
              <Link to="/">ClearTicks Home</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Silver;
