import React,{useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css'
import {
  MdAddTask,
  MdMapsUgc,
  MdOutlineDescription,
  MdOutlineFactCheck,
  MdLock,
  MdAttachFile,
  MdReceipt,
  MdPictureInPictureAlt,
  MdShoppingCart,
  MdOutlineFolder,
  MdBuild,
  MdPerson,
  MdTask,
  MdStarRate,
  MdOutlineMonetizationOn,
  MdCheck,
} from "react-icons/md";

const Modules = () => {
  useEffect(()=>{
     AOS.init({duration:2000})
  },[])
  return (
    <div id="key" className="bg-gray-200 w-full 3xl:w-[2800px] xs:h-[1250px] lg:h-[400px] 3xl:h-[1400px] ip:h-[700px] ip:border-l-4 ip:border-indigo-500 ip:bg-white ip:mt-[40%] ip:w-[700px] lg:mt-[22%] lg:mx-auto lg:w-[1300px] lg:border-l-4 lg:border-indigo-500  lg:bg-white xl:border-l-4 xl:border-indigo-500 xl:bg-white mt-96 md:py-10 py-32 sm-mb-[50%] 3xl:mt-[18%] sa:h-[1200px] sa:w-[320px] sa:ml-5 sa:mt-[90%] sa:bg-gray-50 sa:rounded-xl  xl:mb-[41%] 2xl:mt-[23%] 2xl:h-[500px] 2xl:w-[1500px] xl:w-[1200px] xl:h-[700px] xl:rounded-xl xl:mt-[33%] xl:ml-[6%] xs:mt-[70%] xs:w-[345px] 4xl:w-[1700px] 4xl:mt-[25%] 4xl:h-[550px] ba:mt-[100%] ba:ml-[8%] ca:mt-[78%]">
      <div className="text-3xl 3xl:text-7xl text-center w-full font-sans text-indigo-900 xs:text-3xl xs:mb-[-10%] lg:mb-[-3%] lg:mt-[0%] ip:mt-[3%] ip:text-4xl ip:mb-10 2xl:text-4xl sa:text-2xl sa:mt-[-30%] lg:text-3xl 4xl:text-5xl xl:mt-[0%]">
        <h1 className="font-sans mb-20 tracking-tighter">
          Modules of ClearTicks Practice Management Software
        </h1>
      </div>
      <div className="grid lg:grid-cols-5 ip:grid-cols-3 ip:mt-[-8%] gap-5 3xl:px-60 px-20 text-center font-sans mt-10 xs:mx-8 xl:ml-[12%] ba:mt-[-12%] ba:ml-[3%] lg:mt-[-7%] " data-aos="fade-up">
        <div className="border py-2 px-3 w-[200px] 3xl:text-5xl 2xl:text-2xl mr-5 h-[50px] 3xl:w-[500px] 3xl:h-[100px] 3xl-mt-[50%] rounded-xl shadow-xl flex items-center gap-2 p-3 bg-white 2xl:ml-[-70%] 2xl:w-[250px] 2xl:h-[80px] ip:text-2xl ip:ml-[-50%] ip:w-[200px] ip:h-[80px] lg:h-[50px] lg:w-[215px] lg:ml-[-75%] lg:text-xl xl:w-[250px] xl:h-[80px] xl:mt-[35%] xl:text-2xl 4xl:text-3xl 4xl:w-[300px] 4xl:h-[100px] sa:w-[200px] sa:mt-[-20%] sa:ml-[-10%] sa:h-[50px] sa:bg-neutral-50 xs:w-[200px] xs:h-[50px] xs:-mt-[5%] xs:ml-[-18%] ca:mt-[5%] ba:mt-[3%]">
          <MdAddTask
            size="1.5em"
            className="scale-100 bg-red-500 rounded-full bg-auto text-white l 3xl:p-4"
          />
          <p>To-Do</p>
        </div>
        <div className="border py-2 px-3 w-[200px] rounded-xl shadow-xl flex items-center gap-2 p-5 bg-white 3xl:w-[500px] 3xl:h-[100px] 3xl:text-5xl 2xl:text-2xl 2xl:ml-[-55%] ip:text-2xl ip:ml-[-20%] ip:mt-2 ip:w-[200px] ip:h-[80px] lg:h-[50px] lg:w-[215px] lg:ml-[-50%] lg:text-xl 2xl:mt-[35%] 2xl:w-[250px] 2xl:h-[80px] xl:w-[250px] xl:h-[80px] xl:text-2xl xl:mt-[35%] 4xl:text-3xl 4xl:w-[300px] 4xl:h-[100px] sa:w-[200px] sa:ml-[-10%] sa:h-[50px] sa:bg-neutral-50 xs:w-[200px] xs:h-[50px] xs:ml-[-18%] lg:animate-fadeInUp lg:animate-delay-75">
          <MdMapsUgc
            size="1.5em"
            className="bg-orange-400 fill rounded-full bg-auto text-white 3xl:p-4"
          />
          <p>Contact</p>
        </div>
        <div className="border py-2 px-3 w-[200px] rounded-xl shadow-xl flex items-center gap-2 p-3 bg-white 3xl:w-[500px] 3xl:h-[100px] 3xl:text-5xl 2xl:text-2xl 2xl:ml-[-40%]  2xl:w-[250px] 2xl:h-[80px] ip:text-2xl ip:ml-[10%] ip:mt-2 ip:w-[200px] ip:h-[80px] lg:h-[50px] lg:w-[215px] lg:ml-[-25%] lg:text-xl xl:w-[250px] xl:h-[80px] xl:text-2xl xl:mt-[35%] 4xl:text-3xl 4xl:w-[300px] 4xl:h-[100px] sa:w-[200px] sa:ml-[-10%] sa:h-[50px] sa:bg-neutral-50 xs:w-[200px] xs:h-[50px] xs:ml-[-18%] lg:animate-fadeInUp lg:animate-delay-75">
          <MdOutlineDescription
            size="1.5em"
            className="bg-lime-500 fill rounded-full bg-auto text-white  3xl:p-4"
          />
          <p>File Record</p>
        </div>
        <div className="border py-2 px-3 w-[200px] rounded-xl shadow-xl flex items-center gap-2 p-3 bg-white 3xl:w-[500px] 3xl:h-[100px] 3xl:text-5xl 2xl:text-2xl 2xl:mt-[35%] 2xl:w-[250px] 2xl:ml-[-25%] 2xl:h-[80px] ip:text-2xl ip:ml-[-50%] ip:w-[200px] ip:h-[80px] lg:h-[50px] lg:mt-[35%] lg:w-[215px] lg:ml-[-0%] lg:text-xl xl:w-[250px] xl:h-[80px] xl:text-2xl 4xl:text-3xl 4xl:w-[300px] 4xl:h-[100px] sa:w-[200px] sa:ml-[-10%] sa:h-[50px] sa:bg-neutral-50 xs:w-[200px] xs:h-[50px] xs:ml-[-18%] lg:animate-fadeInUp animate-delay-75">
          <MdOutlineFactCheck
            size="1.5em"
            className="bg-sky-500 fill rounded-full bg-auto text-white 3xl:p-4"
          />
          <p>Task</p>
        </div>
        <div className="border py-2 px-3 w-[200px] rounded-xl shadow-xl flex items-center gap-2 p-3 bg-white 3xl:w-[500px] 3xl:h-[100px] 3xl:text-5xl 2xl:text-2xl 2xl:mt-[35%] 2xl:w-[250px] 2xl:ml-[-10%] 2xl:h-[80px] ip:text-2xl ip:ml-[-20%] ip:w-[200px] ip:h-[80px] lg:h-[50px] lg:mt-[35%] lg:w-[215px] lg:ml-[25%] lg:text-xl xl:w-[250px] xl:h-[80px] xl:text-2xl 4xl:text-3xl 4xl:w-[300px] 4xl:h-[100px] sa:w-[200px] sa:h-[50px] sa:ml-[-10%] sa:bg-neutral-50 xs:w-[200px] xs:h-[50px] xs:ml-[-18%] lg:animate-fadeInUp lg:animate-delay-75">
          <MdLock
            size="1.5em"
            className="bg-fuchsia-500 fill rounded-full bg-auto text-white 3xl:p-4"
          />
          <p>Password</p>
        </div>
        <div className="border py-2 px-3 w-[200px]  h-[50px] rounded-xl shadow-xl flex items-center gap-2 p-3 bg-white 3xl:w-[500px] 3xl:h-[100px] 3xl:text-5xl 2xl:text-2xl 2xl:w-[250px] 2xl:ml-[-70%] 2xl:h-[80px] ip:text-2xl ip:ml-[10%] ip:w-[200px] ip:h-[80px] lg:h-[50px] lg:w-[215px] lg:ml-[-75%] lg:text-xl xl:w-[250px] xl:h-[80px] xl:text-2xl 4xl:text-3xl 4xl:w-[300px] 4xl:h-[100px] sa:w-[200px] sa:ml-[-10%] sa:h-[50px] sa:bg-neutral-50 xs:w-[200px] xs:h-[50px] xs:ml-[-18%] lg:animate-fadeInUp lg:animate-delay-75">
          <MdAttachFile
            size="1.5em"
            className="bg-pink-600 fill rounded-full bg-auto text-white 3xl:p-4"
          />
          <p>Digital Signature</p>
        </div>
        <div className="border py-2 px-3 w-[200px] rounded-xl shadow-xl flex items-center gap-2 p-3 bg-white 3xl:w-[500px] 3xl:h-[100px] 3xl:text-5xl 2xl:text-2xl 2xl:w-[250px] 2xl:h-[80px] 2xl:ml-[-55%] ip:text-2xl ip:ml-[-50%] ip:w-[200px] ip:h-[80px] lg:h-[50px] lg:w-[215px] lg:ml-[-50%] lg:text-xl xl:w-[250px] xl:h-[80px] xl:text-2xl 4xl:text-3xl 4xl:w-[300px] 4xl:h-[100px] sa:w-[200px] sa:ml-[-10%] sa:h-[50px] sa:bg-neutral-50 xs:w-[200px] xs:h-[50px] xs:ml-[-18%] lg:animate-fadeInUp lg:animate-delay-200">
          <MdOutlineMonetizationOn
            size="1.5em"
            className="bg-amber-400 fill rounded-full bg-auto text-white 3xl:p-4"
          />
          <p>Costing</p>
        </div>
        <div className="border py-2 px-3 w-[200px] rounded-xl shadow-xl flex items-center gap-2 p-3 bg-white 3xl:w-[500px] 3xl:h-[100px] 3xl:text-5xl 2xl:text-2xl 2xl:w-[250px] 2xl:h-[80px] 2xl:ml-[-40%] ip:text-2xl ip:ml-[-20%] ip:w-[200px] ip:h-[80px] lg:h-[50px] lg:w-[215px] lg:ml-[-25%] lg:text-xl xl:w-[250px] xl:h-[80px] xl:text-2xl 4xl:text-3xl 4xl:w-[300px] 4xl:h-[100px] sa:w-[200px] sa:ml-[-10%] sa:h-[50px] sa:bg-neutral-50 xs:w-[200px] xs:h-[50px] xs:ml-[-18%]   lg:animate-fadeInUp lg:animate-delay-200">
          <MdReceipt
            size="1.5em"
            className="bg-green-400 fill rounded-full bg-auto text-white 3xl:p-4 "
          />
          <p>Invoice</p>
        </div>
        <div className="border py-2 px-3 w-[200px] rounded-xl shadow-xl flex items-center gap-2 p-3 bg-white 3xl:w-[500px] 3xl:h-[100px] 3xl:text-5xl 2xl:text-2xl 2xl:w-[250px] 2xl:h-[80px] 2xl:ml-[-25%] ip:text-2xl ip:ml-[10%] ip:w-[200px] ip:h-[80px] lg:h-[50px] lg:w-[215px] lg:ml-[-0%]  lg:text-xl xl:w-[250px] xl:h-[80px] xl:text-2xl 4xl:text-3xl 4xl:w-[300px] 4xl:h-[100px] sa:w-[200px] sa:ml-[-10%] sa:h-[50px] sa:bg-neutral-50 xs:w-[200px] xs:h-[50px] xs:ml-[-18%]  lg:animate-fadeInUp lg:animate-delay-200">
          <MdPictureInPictureAlt
            size="1.5em"
            className="bg-red-400 fill rounded-full bg-auto text-white 3xl:p-4"
          />
          <p>Receipt</p>
        </div>
        <div className="border py-2 px-3 w-[200px] rounded-xl shadow-xl flex items-center gap-2 p-3 bg-white 3xl:w-[500px] 3xl:h-[100px] 3xl:text-5xl 2xl:text-2xl 2xl:w-[250px] 2xl:h-[80px] 2xl:ml-[-10%] ip:text-2xl ip:ml-[-50%] ip:w-[200px] ip:h-[80px] lg:h-[50px] lg:w-[215px] lg:ml-[25%] lg:text-xl xl:w-[250px] xl:h-[80px] xl:text-2xl 4xl:text-3xl 4xl:w-[300px] 4xl:h-[100px] sa:w-[200px] sa:ml-[-10%] sa:h-[50px] sa:bg-neutral-50 xs:w-[200px] xs:h-[50px] xs:ml-[-18%] lg:animate-fadeInUp lg:animate-delay-200">
          <MdShoppingCart
            size="1.5em"
            className="bg-teal-300 fill rounded-full bg-auto text-white 3xl:p-4"
          />
          <p>Billing</p>
        </div>
        <div className="border py-2 px-3 w-[200px] h-[50px]  rounded-xl shadow-xl flex items-center gap-2 p-3 bg-white 3xl:w-[500px] 3xl:h-[100px] 3xl:text-5xl 2xl:text-2xl 2xl:w-[250px] 2xl:ml-[-70%] 2xl:h-[80px] ip:text-2xl ip:ml-[-20%] ip:w-[200px] ip:h-[80px] lg:h-[50px] lg:w-[215px] lg:ml-[-75%] lg:text-xl  xl:w-[250px] xl:h-[80px] xl:text-2xl 4xl:text-3xl 4xl:w-[300px] 4xl:h-[100px] sa:w-[200px] sa:ml-[-10%] sa:h-[50px] sa:bg-neutral-50 xs:w-[200px] xs:h-[50px] xs:ml-[-18%] lg:animate-fadeInUp lg:animate-delay-200">
          <MdOutlineFolder
            size="1.5em"
            className="bg-teal-400 fill rounded-full bg-auto text-white 3xl:p-4"
          />
          <p>Folder</p>
        </div>
        <div className="border py-2 px-3 w-[200px] rounded-xl shadow-xl flex items-center gap-2 p-3 bg-white 3xl:w-[500px] 3xl:h-[100px] 3xl:text-5xl 2xl:text-2xl 2xl:w-[250px] 2xl:h-[80px] 2xl:ml-[-55%] ip:text-2xl ip:ml-[10%] ip:w-[200px] ip:h-[80px] lg:h-[50px] lg:w-[215px] lg:ml-[-50%] lg:text-xl xl:w-[250px] xl:h-[80px] xl:text-2xl 4xl:text-3xl 4xl:w-[300px] 4xl:h-[100px] sa:w-[200px] sa:ml-[-10%] sa:h-[50px] sa:bg-neutral-50 xs:w-[200px] xs:h-[50px] xs:ml-[-18%] lg:animate-fadeInUp lg:animate-delay-300">
          <MdBuild
            size="1.5em"
            className="bg-cyan-400 fill rounded-full bg-auto text-white 3xl:p-4 "
          />
          <p>Custom Fields</p>
        </div>
        <div className="border py-2 px-3 w-[200px] rounded-xl shadow-xl flex items-center gap-2 p-3 bg-white 3xl:w-[500px] 3xl:h-[100px] 3xl:text-5xl 2xl:text-2xl 2xl:w-[250px] 2xl:h-[80px] 2xl:ml-[-40%] ip:text-2xl ip:ml-[-50%] ip:w-[200px] ip:h-[80px] lg:h-[50px] lg:w-[215px] lg:ml-[-25%] lg:text-xl xl:w-[250px] xl:h-[80px] xl:text-2xl 4xl:text-3xl 4xl:w-[300px] 4xl:h-[100px] sa:w-[200px] sa:ml-[-10%] sa:h-[50px] sa:bg-neutral-50 xs:w-[200px] xs:h-[50px] xs:ml-[-18%]  lg:animate-fadeInUp lg:animate-delay-300">
          <MdPerson
            size="1.5em"
            className="bg-sky-500 fill rounded-full bg-auto text-white 3xl:p-4"
          />
          <p>Client</p>
        </div>
        <div className="border py-2 px-3 w-[200px] rounded-xl shadow-xl flex items-center gap-2 p-3 bg-white 3xl:w-[500px] 3xl:h-[100px] 3xl:text-5xl 2xl:text-2xl 2xl:w-[250px] 2xl:h-[80px] 2xl:ml-[-25%] ip:text-2xl ip:ml-[-20%] ip:w-[200px] ip:h-[80px] lg:h-[50px] lg:w-[215px] lg:ml-[-0%] lg:text-xl xl:w-[250px] xl:h-[80px] xl:text-2xl 4xl:text-3xl 4xl:w-[300px] 4xl:h-[100px] sa:w-[200px] sa:ml-[-10%] sa:h-[50px] sa:bg-neutral-50 xs:w-[200px] xs:h-[50px] xs:ml-[-18%]  lg:animate-fadeInUp lg:animate-delay-300">
          <MdTask
            size="1.5em"
            className="bg-amber-400 fill rounded-full bg-auto text-white 3xl:p-4 "
          />
          <p>Task Stage</p>
        </div>
        <div className="border py-2 px-3 w-[200px] rounded-xl shadow-xl flex items-center gap-2 p-3 bg-white 3xl:w-[500px] 3xl:h-[100px] 3xl:text-5xl 2xl:text-2xl 2xl:w-[250px] 2xl:h-[80px] 2xl:ml-[-10%] ip:text-2xl ip:ml-[10%] ip:w-[200px] ip:h-[80px] lg:h-[50px] lg:w-[215px] lg:ml-[25%] lg:text-xl xl:w-[250px] xl:h-[80px] xl:text-2xl 4xl:text-3xl 4xl:w-[300px] 4xl:h-[100px] sa:w-[200px] sa:ml-[-10%] sa:h-[50px] sa:bg-neutral-50 xs:w-[200px] xs:h-[50px] xs:ml-[-18%]  lg:animate-fadeInUp lg:animate-delay-300">
          <MdStarRate
            size="1.5em"
            className="bg-orange-400 fill rounded-full bg-auto text-white 3xl:p-4"
          />
          <p>Team</p>
        </div>
        
      </div>
      <div id="task" className="lg:border-r-4 lg:bg-white border-indigo-500 lg:w-[1300px] lg:mt-[8%] lg:h-[500px] text-xl md:text-3xl 3xl:text-7xl lg:text-center mt-20 w-[300px] ml-5 md:w-full font-sans text-indigo-900 lg:mx-auto xs:mt-[25%] xs:h-[990px] xs:text-3xl xs:w-[350px] xs:ml-[0%] ip:mt-20 sa:mt-16 sa:text-2xl sa:w-[320px] sa:bg-gray-50 sa:text-indigo-900 sa:ml-[-1%] sa:h-[85%] sa:rounded-xl 2xl:text-4xl ip:text-4xl ip:h-[570px] ip:border-r-4 ip:border-indigo-500 ip:bg-white xl:ml-0 xl:mt-[13%] xl:w-[1200px] xl:h-[600px] xl:border-r-4 xl:border-indigo-500 xl:bg-white xl:rounded-2xl 2xl:w-[1500px] 2xl:mt-[9%] 2xl:h-[500px] xs:rounded-xl 4xl:w-[1700px] 4xl:mt-[6%] 4xl:text-5xl 4xl:h-[600px] ba:ml-[0%] ca:mt-[20%]">
        <h1 className="font-sans tracking-tighter lg:mb-[-3%] lg:ml-20 ip:mr-28 lg:p-8 sa:p-4 xl:ml-48 xl:p-8">
          How ClearTicks helps in your Practice Management?
        </h1>
        <div className="grid lg:grid-cols-2 xs:grid-cols-1 ip:grid-cols-2 px-2  lg:mt-10 ml-10 text-center font-sans w-[280px] 3xl:h-[500px] lg:ml-20 ">
          <div className="border py-8 rounded-xl shadow-xl mr-2 3xl:w-[1200px] ip:w-[350px] ip:h-[400px] ip:ml-[-50%] ip:mt-5 lg:ml-[0%] lg:w-[500px] lg:h-[320px] lg:bg-gradient-to-r from-cyan-500 to-blue-500 lg:text-xl lg:mt-[20%] xs:ml-[-5%] xs:w-[280px] xs:mt-[10%] xs:bg-sky-500 xs:shadow-xl sa:bg-sky-500 sa:ml-[-12%] sa:text-base 2xl:ml-[50%] xl:ml-[40%] xl:w-[450px] ip:text-xl xl:mt-[25%] 4xl:w-[700px] 4xl:ml-[10%] 4xl:h-[430px] lg:hover:-translate-y-2 transition ease-in-out delay-150 hover:scale-110 duration-300 " data-aos="zoom-in">
            <img src="" />
            <p className="text-2xl 3xl:text-6xl md:text-3xl sa:text-2xl font-bold text-white font-sans 4xl:text-5xl ">
              Team Management
            </p>
            <ul>
              <li className="flex text-white  font-sans text-left 3xl:text-5xl ">
                <MdCheck
                  size="1.5rem"
                  className="text-white 3xl:text-6xl xs:w-10"
                />
                <p className="md:ml-2 lg:text-xl xl:text-2xl xs:text-xl ip:text-2xl 4xl:text-4xl">
                  Track which task is assigned to which user
                </p>
              </li>
              <li className="flex text-white   font-sans text-left 3xl:text-5xl">
                <MdCheck
                  size="1.5rem"
                  className="text-white 3xl:text-6xl xs:w-10"
                />
                <p className="md:ml-2 ip:text-2xl lg:text-xl xs:text-xl 4xl:text-4xl">
                  Calculate how many hours are spend on same type of tasks
                </p>
              </li>
              <li className="flex text-white  font-sans text-left 3xl:text-5xl ip:text-2xl">
                <MdCheck
                  size="1.5rem"
                  className="text-white 3xl:text-6xl xs:w-10"
                />
                <p className="md:ml-2 ip:text-2xl lg:text-xl xs:text-xl 4xl:text-4xl">
                  Track which task is assigned to which user
                </p>
              </li>
            </ul>
          </div>

          <div className="border absolute md:py-8 mt-5 md:mt-0 rounded-xl shadow-xl md:w-[300px]w-[245px] 3xl:ml-[140%] 3xl:w-[1200px] ip:w-[350px] ip:ml-[35%] ip:h-[400px] ip:mt-5 lg:w-[500px] lg:h-[320px] lg:text-xl lg:mb-48 lg:mt-[2%] lg:ml-[40%] lg:bg-gradient-to-r from-cyan-500 to-blue-500 xs:mt-[105%] xs:mr-[14%] xs:ml-[-3%] xs:bg-sky-500  xs:shadow-xl sa:mt-[100%] sa:text-base sa:bg-sky-500 sa:w-[280px] 2xl:ml-[45%] xl:h-[368px] xl:ml-[44%] xl:mt-[2.5%] xl:w-[450px] sa:ml-[-8%] 4xl:w-[700px] 4xl:ml-[42%] 4xl:mt-[1%] 4xl:h-[430px] lg:hover:-translate-y-2 transition ease-in-out delay-150 hover:scale-110 duration-300" data-aos="zoom-in">
            <p className="text-xl md:text-3xl 3xl:text-6xl font-bold text-white  font-sans mt-0 xs:mt-10 lg:mt-0 ip:mt-0 sa:mt-10 xs:text-2xl sa:text-2xl 4xl:text-5xl">
              Client Management
            </p>
            <ul>
              <li className="flex text-white  font-sans text-left 3xl:text-5xl">
                <MdCheck
                  size="1.5rem"
                  className="text-white 3xl:text-6xl xs:w-10"
                />
                <p className="md:ml-2 ip:text-2xl lg:text-xl xs:text-xl 4xl:text-4xl">
                  Group clients into client group for ease of tracking
                </p>
              </li>
              <li className="flex text-white   font-sans text-left 3xl:text-5xl ip:text-2xl">
                <MdCheck
                  size="1.5rem"
                  className="text-white 3xl:text-6xl xs:w-10"
                />
                <p className="md:ml-2 ip:text-2xl lg:text-xl xs:text-xl 4xl:text-4xl">
                  Rich insights on which client group provides best
                  profitability
                </p>
              </li>
              <li className="flex text-white  font-sans text-left 3xl:text-5xl ip:text-2xl">
                <MdCheck
                  size="1.5rem"
                  className="text-white 3xl:text-6xl xs:w-10"
                />
                <p className="md:ml-2 ip:text-2xl lg:text-xl xs:text-xl 4xl:text-4xl">
                  Built in Contact and Password management
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modules;
