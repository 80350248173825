import React from "react";
import Taskimg1 from "../assets/Taskimage1.webp";
import Taskimg2 from "../assets/Taskimage2.webp";
import Taskimg3 from "../assets/Taskimage3.webp";
import Taskimg4 from "../assets/Taskimage4.webp";

const Tabs = ({ color }) => {
  const [openTab, setOpenTab] = React.useState(1);
  return (
    <>
      <div className="flex flex-wrap bg-gray-100 lg:w-[90%] xs:mt-[280%] xs:w-[350px] xs:ml-8 rounded-xl md:mt-20 md:ml-48 mb-[10%] lg:mt-[38%] lg:ml-20 lg:mb-[0] 3xl:mt-[30%] ip:mt-[80%] ip:ml-10 sa:mt-[250%] ip:bg-white 2xl:ml-[7%] xl:ml-[2%] xl:w-[0%] xl:mt-[50%] 2xl:mt-[40%] sa:bg-white ba:mt-[240%] 4xl:mt-[35%]" data-aos="fade-up">
        <div className="w-[400px] 3xl:w-[900px] ip:w-[250px] lg:w-[400px] sa:w-[350px] 2xl:w-[400px]">
          <div className="text-3xl 3xl:text-7xl lg:text-4xl ip:text-4xl ip:w-[600px] ip:ml-20 font-sans text-indigo-900 lg:w-[500px] lg:p-5 lg:ml-20 xs:text-3xl xs:ml-[4%] xl:text-4xl 2xl:text-4xl sa:text-2xl sa:text-center sa:mt-5 xl:ml-8 2xl:ml-20 4xl:text-5xl 4xl:w-[600px] ba:ml-[-9%]">
            <h2>Task Organizer Overview</h2>
          </div>
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-col 2xl:w-[600px] xl:w-[600px]"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-left 2xl:ml-28 xl:ml-28 xl:text-3xl">
            <a
                className={
                  "text-base 3xl:text-5xl ip:text-2xl lg:text-base xl:text-2xl 2xl:text-2xl 4xl:text-4xl font-sans px-2 py-1 rounded block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-" + color + "-600"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                  Task & Recurrence Task Management
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-left 2xl:ml-28 xl:ml-28 ">
              <a
                className={
                  "text-base 3xl:text-5xl ip:text-2xl lg:text-base xl:text-2xl 2xl:text-2xl 4xl:text-4xl font-sans px-2 py-1 rounded block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-" + color + "-600"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Leave Management
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-left 2xl:ml-28 xl:ml-28 ">
              <a
                className={
                  "text-base 3xl:text-5xl ip:text-2xl lg:text-base xl:text-2xl 2xl:text-2xl 4xl:text-4xl font-sans px-2 py-1 rounded block leading-normal " +
                  (openTab === 3
                    ? "text-white bg-" + color + "-600"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                Documents
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-left 2xl:ml-28 xl:ml-28">
              <a
                className={
                  "text-base 3xl:text-5xl ip:text-2xl lg:text-base xl:text-2xl 2xl:text-2xl 4xl:text-4xl font-sans px-2 py-1 rounded block leading-normal " +
                  (openTab === 4
                    ? "text-white bg-" + color + "-600"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(4);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                Client Management
              </a>
            </li>
          </ul>
          <div className=" min-w-0 break-words bg-white w-full -mb-96 3xl:ml-[30%] -3xl:mt-[50%] ip:ml-[28%] sa:mt-[110%] lg:mt-0 xl:mb-0">
            <div className=" flex-auto xl:mt-[-230%] ip:mt-[-190%] xs:ml-[-85%] lg:ml-48 lg:mt-[-10%] ip:ml-[10%] 2xl:mt-[-9%]">
              <div className="flex tab-content -mt-48 w-[600px] ip:w-[400px] ml-96px xs:w-[300px] lg:w-[600px] sa:ml-[10%] sa:w-[230px] lg:ml-[185%] lg:mt-[-130%] 2xl:ml-[200%] 2xl:mt-[-160%] xl:text-right xl:mt-[0%] xl:ml-[270%] xs:ml-[50%] xs:mr-[2%] 4xl:mt-[-160%] 4xl:ml-[270%]">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <p>
                    <img
                      className="w-[500px] h-[300px] -mt-48 md:mt-0 3xl:w-[1000px]  3xl:h-[500px] 3xl:mt-[-20%]  xs:mt-[-70%] lg:mr-[20%] ip:mt-[0%]"
                      src={Taskimg1}
                      alt=""
                    ></img>
                  </p>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <p>
                    <img
                      className="w-[500px] h-[300px] -mt-48 md:mt-0 xs:mt-[-70%]"
                      src={Taskimg2}
                      alt=""
                    ></img>
                  </p>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <p>
                    <img
                      className="w-[500px] h-[300px] -mt-48 md:mt-0 xs:mt-[-70%]"
                      src={Taskimg3}
                      alt=""
                    ></img>
                  </p>
                </div>
                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  <p>
                    <img
                      className="w-[500px] h-[300px] -mt-48 md:mt-0 xs:mt-[-70%] "
                      src={Taskimg4}
                      alt=""
                    ></img>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function TabsRender() {
  return (
    <>
      <Tabs color="pink" />
    </>
  );
}
