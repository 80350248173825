import React, { useState, useEffect } from "react";
import bgimg from "../assets/avatarimg.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const url = "https://zta.digital/processor.php";

const Inquire = () => {
  const initialValues = { name: "", email: "", password: "", cell: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();

  let formData = new FormData();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    const { name, email, password, cell } = formValues;
    setFormErrors(validate(formValues));
    if (name === "" || email === "" || password === "" || cell === "") {
      return false;
    }
    e.preventDefault();
   
    setIsSubmit(true);
    //formdata object
    formData.append("name", name); //append the values with key, value pair
    formData.append("email", email);
    formData.append("password", password);
    formData.append("cell", cell);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    await axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response);
        navigate("/silver")
      })
      .catch((error) => {
        console.log(error);
    
      });
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.name) {
      errors.name = "Name is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    } else if (values.password.length > 10) {
      errors.password = "Password cannot exceed more than 10 characters";
    }
    if (!values.cell) {
      errors.cell = "Mobile Number is required";
    }
    return errors;
  };

  return (
    <div className="w-full h-screen flex ">
      <div className="grid grid-cols-1 md:grid-cols-2 m-auto lg:w-[1300px] lg:h-[800px] shadow-lg shadow-gray-600 sm:max-w-[1000px] xs:w-[350px] xs:rounded-xl ip:w-[730px] ip:mt-20 ip:mx-auto lg:ml-[18%] 2xl:mx-auto sa:w-[300px] sa:mr-8 sa:p-4 sa:rounded-xl xl:mx-auto ba:mt-[30%] ba:ml-[9%] xs:ml-[9%] sa:mt-[30%] ca:mt-[10%] xs:mt-[10%]">
        <div className="lg:mt-20 hidden md:block ip:mr-[10%]">
          <h1 className="text-3xl font-sans ml-20 lg:mt-10 ip:mt-20">
            Task Organizer <span className="text-gray-400">Silver</span>
          </h1>
          <div className="flex mt-20 ml-20">
            <img
              className="lg:w-[100px] lg:h-[100px] ip:w-[100px]"
              src={bgimg}
              alt="/"
            />
            <h2 className="lg:mt-10 lg:ml-5 lg:text-xl font-bold font-sans ip:mt-10 ip:ml-5">
              Our Client Says
            </h2>
          </div>
          <p className="lg:p-8 text-gray-500 ip:p-4">
            "My long time search for user friendly and cost effective Office
            management software ends with starting the usage of ZTA's Task
            management software. I highly recommend the software since I derive
            the benefits of advance planning, timely execution, work monitoring,
            client satisfaction and avoidance of late fees and penalties due to
            usage of software. Best wishes and kudos to ZTA team for
            introduction of this excellent software."
          </p>
        </div>
        <div className="lg:p-4 flex flex-col justify-around xs:p-4">
          < >
            <div>
              <h2 className="font-sans lg:text-3xl lg:mt-5 lg:mr-20 text-start font-bold mb-8 text-gray-600 xs:text-2xl xs:text-center ip:mr-8 xl:w-[600px] xl:ml-[-45%] lg:ml-[-23%] ba:text-xl sa:mt-[22%] sa:text-xl">
                Quick Registration for Trial
              </h2>
              <h3 className="text-gray-500 xs:text-xl ">
                Great! You're just a few steps away from using Task Organizer.
              </h3>
            </div>
            <div className="flex flex-col mb-4 lg:text-base 3xl:text-5xl lg:mt-10 text-gray-600 xs:text-xl  ba:mt-[5%] ca:mt-[5%]">
              <lable>Your Name</lable>
              <input
                className="border relative bg-gray-100 p-2"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <p className="text-red-600">{formErrors.name}</p>
            </div>
            <div className="flex flex-col mb-4 lg:text-base 3xl:text-5xl text-gray-600 xs:text-xl">
              <lable className="font-sans">Email address</lable>
              <input
                className="border relative bg-gray-100 p-2"
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <p className="text-red-600">{formErrors.email}</p>
            </div>
            <div className="flex flex-col mb-4 lg:text-base 3xl:text-5xl text-gray-600 xs:text-xl">
              <lable className="font-sans">Password</lable>
              <input
                className="border relative bg-gray-100 p-2"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              <p className="text-red-600">{formErrors.password}</p>
            </div>
            <div className="flex flex-col mb-4 lg:text-base 3xl:text-5xl text-gray-600 xs:text-xl">
              <lable className="font-sans">Mobile</lable>
              <input
                className="border relative bg-gray-100 p-2"
                type="number"
                name="cell"
                value={formData.cell}
                onChange={handleChange}
              />
              <p className="text-red-600">{formErrors.cell}</p>
            </div>
            <p className="font-sans text-gray-500">
              By proceeding, you agree to the{" "}
              <span className="text-indigo-600">Terms of Service</span> and{" "}
              <span className="text-indigo-600">Privacy Policy.</span>
            </p>
            <button
              type="submit" onClick={handleSubmit}
              className="w-full py-2 my-4 font-sans text-2xl hover:text-white bg-green-600 hover:bg-green-500 "
            >
              Get Trial Link via Email
            </button>
          </>
        </div>
      </div>
    </div>
  );
};

export default Inquire;
