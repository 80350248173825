import React from "react";
import loginimage from "../assets/loginimage.jpg";

const Login = () => {
  return (
    <div className="w-full h-screen flex">
      <div className="grid grid-cols-1 md:grid-cols-2 m-auto lg:w-[1300px] lg:h-[550px] lg:mt-[2%] ip:w-[700px] shadow-lg shadow-gray-600 rounded-xl sm:max-w-[1000px] xs:w-[350px] xs:mt-[15%] 3xl:w-[1400px]">
        <div className="lg:mt-0 lg:mr-10 hidden md:block">
          <div className="lg:mt-20 lg:ml-20">
            <img className="w-[400px] h-[250px]" src={loginimage} alt="/" />
            <p className="lg:mt-10 lg:ml-5 lg:text-base font-bold font-sans text-gray-600 ip:p-4">
              Track teams tasks and follow up important tasks with simple and
              powerful task management features.
            </p>
          </div>
        </div>
        <div className="p-4 flex flex-col justify-around">
          <form>
            <div>
              <h2 className="font-sans lg:text-3xl text-start font-bold lg:mb-0 text-gray-600 xs:mb-[10%] xs:text-2xl xs:text-center">
                Task Organizer
              </h2>
              <h3 className="text-gray-600 lg:text-2xl lg:mb-6 xs:text-xl">
                Sign in
              </h3>
            </div>
            <hr className="lg:mb-5 xs:mb-10" />
            <div className="flex flex-col mb-4 3xl:text-5xl text-gray-600 xs:text-xl">
              <lable className="font-sans">Email address</lable>
              <input
                className="border relative bg-gray-100 p-2"
                type="text"
                name="email"
              />
            </div>
            <div className="flex flex-col mb-4 3xl:text-5xl text-gray-600 xs:text-xl">
              <lable className="font-sans">Password</lable>
              <input
                className="border relative bg-gray-100 p-2"
                type="password"
                name="password"
              />
            </div>
            <button className="w-full py-2 my-4 font-sans text-2xl hover:text-white bg-green-600 hover:bg-green-500">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
