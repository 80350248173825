import React from "react";
import bgimg from "../assets/cyber-bg.png";
import waimg from "../assets/WhatsAppicon.png";
import Records from "./Records";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div
      id="home"
      name="home"
      className="w-full h-screen 3xl:h-[1100px] ip:h-[600px] bg-pink-50 xl-bg-balck flex flex-col justify-between sa:w-full 2xl:w-full "
    >
      <div className="grid md:grid-cols-2 max-w-[1240px] m-auto ">
        <div className="flex flex-col justify-center md:items-start w-full px-2  xs:text-3xl text-2xl 3xl:mt-20 3xl:ml-[-30%] 3xl:w-[600px] xs:ml-[-3%] xs:mt-[-20%] lg:mt-[13%] lg:ml-28 xs:text-center ip:mt-0 ip:ml-10 2xl:mt-20 xl:mt-10 xl:ml-32 sa:mt-[-20%] sa:ml-[-4%] 4xl:text-8xl 4xl:w-[900px] 4xl:ml-[-20%] ba:ml-0 ">
          {Records.map((Records) => {
            return (
              <div>
                <h1 className="py-3 xs:text-3xl xs:ml-5 lg:ml-10  lg:text-5xl text-1xl mb-0 md:mb-10 text-center  md:text-6xl font-sans tracking-tighter text-indigo-900 3xl:text-9xl lg:animate-fadeInLeft animate-slower-15s">
                  {Records.title1}
                </h1>
                <p className="my-4 ml-5 xs:text-xl xs:mr-6 lg:text-2xl lg:mr-1 font-sans mb-10 text-lg text-slate-600 font-light tracking-wide 3xl:text-5xl 3xl:w-[150%] 3xl:ml-[-20%] ip:text-2xl 4xl:text-4xl lg:animate-fadeInRight animate-delay-300">
                  {Records.content1}
                </p>
              </div>
            );
          })}
        </div>
        <div className="xs:w-[400px] lg:w-[500px] 3xl:mt-32 3xl:w-[1000px] 3xl:ml-0 ip:mt-32 xl:ml-16 4xl:w-[700px] 4xl:mt-20">
          <img
            className="w-full -mt-32 md:mt-0 lg:ml-0 lg:animate-fadeInRight animate-delay-500"
            src={bgimg}
            alt=""
          ></img>
        </div>
        <div className="absolute flex flex-col py-6 lg:min-w-[760px] lg:mt-[35%] lg:h-[150px] xs:mb-[30%] xs:mt-[150%] xs:ml-[14%] xs:w-[300px] xs:h-[200px] lg:w-[1000px] my-48 3xl:mb-[55%] 3xl:w-[70%] 3xl:h-[200px] ip:mt-[60%] ip:w-[600px] ip:h-[200px] ip:mr-[0%] md:right-1/2 transform md:translate-x-1/2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 w-5/6 border-slate-300 rounded-xl text-center shadow-xl mx-7 lg:mx-auto sa:mt-[155%] 2xl:mt-[32%] xl:mt-[38%] xl:w-[1000px] xl:h-[150px] xl:mx-auto 4xl:w-[1200px] 4xl:h-[150px] ba:ml-[8%] ba:mt-[160%] ca:mt-[150%] lg:animate-fadeInTopLeft animate-delay-300 ">
          <div className="flex justify-center flex-wrap py-4 ">
            <p className="flex px-4 py-2 mt-2 ml-10 text-white font-sans xs:text-xl xs:h-[80px] xs:px-1 lg:h-[50px] lg:text-2xl lg:mr-10 text-2xl font-semi-bold tracking-normal mr-10 3xl:text-7xl ip:text-3xl 3xl:mr-48  2xl:text-4xl xl:text-3xl 4xl:text-4xl">
              Start Your Free Trial Try It Now!
            </p>
            <button className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2 text-center mr-2 flex justify-center ml-2 font-sans rounded-xl hover:bg-sky-700 hover:text-white content-center 3xl:w-[500px] 3xl:text-6xl 3xl:rounded-full 3xl:p-8 3xl:mt-[0%] xs:mt-0 xs:texl-2xl lg:text-2xl ip:text-xl ip:mt-[0%] 2xl:text-3xl xl:text-2xl xl:h-[60px] 4xl:text-4xl 4xl:mt-[1%] animate-pulse">
              <Link to="/register">Start Free Trial</Link>
            </button>
          </div>
        </div>
      </div>
      <div className="lg:fixed z-40 bottom-0 lg:ml-[90%] lg:mb-[5%] lg:w-10 2xl:w-16 2xl:ml-[88%] 2xl:mb-[4%] 2xl:fixed rounded-full xl:mb-[4%] xl:fixed xl:ml-[90%] xl:w-10 4xl:ml-[88%] 4xl:mb-[3%] sa:w-10 sa:ml-[72%] sa:fixed xs:fixed ca:ml-[77%] ca:mb-[5%] xs:ml-[77%] ip:ml-[75%] ip:w-16 ip:mb-[10%]">
        <a href="https://wa.me/+917530001564" target="_blank">
          <img
            src={waimg}
            alt="whatsapp image"
            className="animate-heartBeat animate-infinite rounded-full"
          />
        </a>
      </div>
      <div name="about" className=" md:my-10 my-60 ">
        <div className="max-w-[1240px] mx-auto 3xl:ml-96 lg:mt3 xl:mt-[-3%] xl:ml-[6%] xl:break-normal xl sa:w-[400px] sa:mt-[-20%] lg:ml-[7%] ip:mt-[-20%] ip:mr-[15%] 2xl:ml-20 2xl:mt-20 4xl:mt-[8%] 4xl:ml-28 ba:mt-[0%] ba:ml-[2%] xs:mt-[-19%]ca:mt-[10%]">
          {Records.map((Records) => {
            return (
              <div className="absolute text-left sa:w-[270px] mt-32 2xl:mt-[4%]">
                <h2 className="text-4xl 3xl:text-8xl font-sans tracking-tighter text-indigo-900 3xl:mt-20 3xl:mr-96 ip:mt-20 ip:w-[500px] ip:text-5xl lg:text-4xl lg:mt-[-3%] lg:w-[300px] lg:ml-0 xs:mb-[0%] 2xl:text-4xl sa:text-3xl sa:ml-2 sa:mx-auto xs:mt-[18%] xs:text-3xl 4xl:w-[600px] 4xl:text-6xl xs:ml-8 xl:w-[400px]">
                  {Records.title2}
                </h2>
                <p className="py-6 text-slate-600 font-sans text-xl 3xl:text-5xl 3xl:w-[80%] ip:text-3xl lg:w-[1350px] lg:text-2xl lg:ml-0 2xl:text-3xl 2xl:w-[1500px] sa:flex-wrap sa:w-[330px] sa:ml-3 sa:text-justify xl:text-3xl 4xl:text-4xl 4xl:w-[1700px] xs:text-2xl xs:ml-8 xl:w-[1200px] ip:w-[700px]">
                  {Records.content2}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Hero;
