import React from "react";
import { MdCheck, MdOutlineKeyboardArrowDown } from "react-icons/md";
import Records from "./Records";

const Pricing = () => {
  return (
    <div>
      <div className="text-center ">
        {Records.map((Records) => {
          return (
            <div>
              <h1 className="font-serif text-4xl 3xl:text-7xl text-black-500 mt-10 ml-50 tracking-tighter text-indigo-900 4xl:text-5xl">
                {Records.title3}
              </h1>
              <p className="text-2xl font-serif text-slate-600 font-light tracking-wide">
                {Records.content3}
              </p>
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 relative gap-x-8 gap-y-16 px-10 pt-14 sm:pt-20 text-black lg:mr-20 sa:-z-40">
        <div className="bg-white rounded-xl shadow-2xl w-[300px] h-[420px] md:h-[720px] md:w-[400px] 3xl:w-[800px] 3xl:h-[1300px] lg:ml-[10%] lg:h-[900px] lg:w-[400px] ip:h-[500px] ip:w-[500px] ip:ml-[16%] -ml-2 md:ml-16 md:-mt-10 xs:w-[330px] xs:ml-[0%] xl:ml-[0%]  xl:h-[900px] xl:w-[400px] 2xl:ml-16 sa:ml-[-4%] 4xl:w-[500px] 4xl:h-[900px]  ba:ml-[0%] sa:-z-20 ">
          <div className="p-8 grid ">
            <h3 className="font-serif text-2xl 3xl:text-6xl my-6 tracking-tighter text-indigo-900 4xl:text-4xl ip:text-3xl">
              Standard Pack
            </h3>
            <h4 className="text-3xl 3xl:text-6xl text-gray-600 4xl:text-3xl">
              ₹ 5,000 <span className="text-xl 3xl:text-5xl 4xl:text-2xl">(per year)</span>
            </h4>
            <hr className="text-2xl gap-8 mt-5" />
            <div className="flex ">
              <MdCheck className="mt-5 text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl 3xl:text-5xl  mt-4 ml-4 3xl:mt:20 4xl:text-2xl ip:text-xl ">
                Support to setup all the office configuration
              </p>
            </div>
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl 3xl:text-5xl mt-4 ml-4 4xl:text-2xl ip:text-xl">
                 Customised training on your office account
              </p>
            </div>
            <div>
            <button class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800 lg:text-xl lg:mt-[138%] lg:ml-[25%] 4xl:mt-[110%] 4xl:ml-[30%] xl:ml-[25%] xl:mt-[140%] sa:ml-[20%] sa:mt-[20%] xs:ml-[25%] ip:ml-[30%] ip:text-2xl ip:mt-[30%]">
           <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
             Choose plan
            </span>
      </button>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-xl md:ml-8 shadow-2xl -mt-10 -ml-2 w-[300px] h-[520px] md:h-[720px] md:w-[400px] 3xl:w-[800px] lg:mt-[-9%] lg:ml-[12%] lg:h-[900px] lg:w-[400px] ip:w-[500px] ip:ml-[16%] 3xl:h-[1300px] 3xl:mr-5 ip:h-[555px] xs:w-[330px] xs:ml-[0%] xl:ml-[34%] xl:w-[400px] xl:mt-[-75%] xl:h-[900px] 2xl:ml-20 2xl:mt-[-8%] sa:ml-[-5%] 4xl:w-[500px] 4xl:h-[900px] ba:ml-[0%] ">
          <div className="p-8">
            <h3 className="font-serif text-2xl 3xl:text-6xl my-6 tracking-tighter text-indigo-900 4xl:text-4xl ip:text-3xl">
              Premium Pack
            </h3>
            <h4 className="text-3xl 3xl:text-6xl text-gray-600 4xl:text-3xl">
              ₹ 9,000 <span className="text-xl 3xl:text-5xl 4xl:text-2xl">(per year)</span>
            </h4>
            <hr className="text-2xl gap-8 mt-5" />
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl mt-4 ml-4 3xl:text-5xl 4xl:text-2xl ip:text-xl">
                Support to setup all the office configuration
              </p>
            </div>
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl lg:text-xl xl:text-xl" />
              <p className="text-gray-600 text-1xl mt-4 ml-4 3xl:text-5xl 4xl:text-2xl ip:text-xl">
                Customised training on your office account
              </p>
            </div>
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl mt-4 ml-4 3xl:text-5xl 4xl:text-2xl ip:text-xl">
                File upload activation
              </p>
            </div>
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl mt-4 ml-4 3xl:text-5xl 4xl:text-2xl ip:text-xl">
                10 GB storage space for 1 year
              </p>
            </div>
            <div>
            <button class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800 lg:text-xl lg:mt-[113%] lg:ml-[25%] 4xl:ml-[30%] 4xl:mt-[88%] xl:ml-[25%] xl:mt-[114%] sa:ml-[20%] sa:mt-[20%] xs:ml-[25%] ip:ml-[30%] ip:text-2xl">
           <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
             Choose plan
            </span>
    </button>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-xl shadow-2xl -mt-10 -ml-2 w-[300px] h-[750px] md:h-[720px] md:w-[400px] 3xl:w-[800px] 3xl:h-[1300px] lg:h-[900px] lg:w-[400px] ip:h-[900px] ip:w-[500px] ip:ml-[16%] xs:w-[330px] xs:ml-[0%] xl:ml-[68%] xl:w-[400px] xl:h-[900px] xl:mt-[-80%] 2xl:ml-[20%] 2xl:mt-[-8%] lg:mt-[-9%] lg:ml-[13%] sa:ml-[-4%] sa:h-[830px] 4xl:w-[500px] 4xl:h-[900px]  4xl:ml-[15%] ba:ml-[0%]">
          <div className="p-8">
            <h3 className="font-serif text-2xl 3xl:text-6xl my-6 tracking-tighter text-indigo-900 4xl:text-4xl ip:text-3xl">
              Ultimate Pack
            </h3>
            <h4 className="text-3xl 3xl:text-6xl text-gray-600 4xl:text-3xl">
              ₹ 12,000 <span className="text-xl 3xl:text-5xl 4xl:text-2xl">(per year)</span>
            </h4>
            <hr className="text-2xl gap-8 mt-5" />
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl mt-4 ml-4 3xl:text-5xl 4xl:text-2xl ip:text-xl">
                ALL Features
              </p>
            </div>
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl mt-4 ml-4 3xl:text-5xl 4xl:text-2xl ip:text-xl">
                Email Suppor
              </p>
            </div>
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl mt-4 ml-4 3xl:text-5xl 4xl:text-2xl ip:text-xl">
                Phone Support
              </p>
            </div>
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl mt-4 ml-4 3xl:text-5xl 4xl:text-2xl ip:text-xl">
                Configuration Setup
              </p>
            </div>
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl mt-4 ml-4 3xl:text-5xl 4xl:text-2xl ip:text-xl">
                Data Importing
              </p>
            </div>
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl mt-4 ml-4 3xl:text-5xl 4xl:text-2xl ip:text-xl">
                Training (for)Data Import
              </p>
            </div>
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl mt-4 ml-4 3xl:text-5xl 4xl:text-2xl ip:text-xl">
                Support to setup all the office configuration
              </p>
            </div>
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl mt-4 ml-4 3xl:text-5xl 4xl:text-2xl ip:text-xl">
                Customised training on your office account
              </p>
            </div>
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl mt-4 ml-4 3xl:text-5xl 4xl:text-2xl ip:text-xl">
                File upload activation
              </p>
            </div>
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl mt-4 ml-4 3xl:text-5xl 4xl:text-2xl ip:text-xl">
                20 GB storage space for 1 year
              </p>
            </div>
            <div className="flex ">
              <MdCheck className="mt-5  text-green-600 3xl:text-6xl ip:text-2xl lg:text-xl" />
              <p className="text-gray-600 text-1xl mt-4 ml-4 3xl:text-5xl 4xl:text-2xl ip:text-xl">
                Geo location based attendance for 1 year
              </p>
            </div>
            <div>
            <button class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800 lg:text-xl lg:mt-[13%] lg:ml-[25%] 4xl:ml-[30%] 4xl:mt-[4%] xl:ml-[25%] xl:mt-[14%] sa:ml-[20%] sa:mt-[20%] xs:ml-[25%] ip:ml-[30%] ip:text-2xl">
           <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
             Choose plan
            </span>
    </button>
            </div>
          </div>
        </div>
        <div className="absolute lg:mt-[70%] xl:mt-[80%] sa:mt-[540%] ba:mt-[520%] xs:mt-[470%] ip:mt-[260%] 4xl:mt-[60%]">
        <div className="text-center 3xl:w-full ">
          <p className=" text-2xl md:text-3xl 3xl:text-6xl font-sans  tracking-tighter text-indigo-900 ip:mt-[0%] sa:ml-[8%] lg:ml-[-50%] ba:ml-[18%] xs:ml-[20%] xl:ml-[-67%] 4xl:text-4xl 2xl:ml-[-48%] ip:ml-[-30%]">
            Frequently asked Question(FAQ)
          </p>
          <div className="relative w-[310px] 3xl:w-[170%] md:overflow-hidden mt-16 lg:mb-[10%] lg:ml-[9%] lg:mt-[0%] xl:ml-[0%] sa:ml-[12%] ba:ml-[16%] xs:ml-[20%] sa:mt-[0%] ip:w-[80%] ip:ml-[9%] lg:w-full ip:mb-20 2xl:w-full xl:w-full">
            <input
              type="checkbox"
              className=" peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
            />
            <div className="bg-white-500 h-12 w-full pl-5 flex items-center">
              <h1 className="text-sm md:text-lg 3xl:text-5xl font-semibold border-b-4 text-left md:w-full font-sans text-indigo-900 mr-20 xl:text-2xl lg:text-xl 4xl:text-3xl ip:text-2xl">
                ✔ Does the price include GST?
              </h1>
            </div>
            <div className="absolute top-3 right-3 mr-8 text-indigo-900 transition-transform duration-500 rotate-0 peer-checked:rotate-180 z-50">
              <MdOutlineKeyboardArrowDown size="3em"/>
            </div>
            <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-40 font-sans">
              <div className="p-4 font-sans text-gray-600">
                <p className="text-left 3xl:text-5xl xl:text-2xl lg:text-xl ip:text-2xl 4xl:text-2xl">
                  All prices above are excluding GST.GST will be levied at 18%
                  over and above the price quoted above. GST invoice will be
                  issued after payment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
  );
};

export default Pricing;
