import React from "react";
import { MdOutlinePhoneInTalk, MdOutlineLocalPostOffice } from "react-icons/md";
import { Link } from "react-router-dom";
import Backbutton from "./Backbutton";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white lg:mt-[3%] lg:mb-0 ip:mb-0 ip:mt-0 sa:mt-[110%] ip:text-2xl lg:text-xl xl:text-2xl 2xl:text-2xl ip:py-5 xl:mt-[-8%] 2xl:mt-[1%] xs:text-xl xs:mt-[100%] sa:text-xl 4xl:text-3xl 4xl:h-[500px]">
      <div className="lg:p-8 ip:h-[400px] xl:p-4 sa:w-[300px] xs:w-[300px] 4xl:w-[500px]">
        <div className="lg:ml-20">
          <h1 className="text-sky-400 lg:text-2xl lg:ml-0 xs:ml-4 ip:text-4xl sa:ml-3 2xl:text-3xl sa:text-2xl xs:text-3xl 4xl:text-5xl">
            Quick Links
          </h1>
        </div>
        <div className="lg:ml-16 flex leading-none">
          <ul>
            <li className="scroll-smooth"><a href="#home">Home</a></li>
            <li className="sa:whitespace-nowrap"><a href="#task">Why Task Organizer</a></li>
            <li><a href="#key">Key features</a></li>
            <li>Testimonials</li>
            <li><Link to="/inquire">Contact us</Link></li>
          </ul>
          <div className="sa:mt-[-7%] lg:mt-0 ip:ml-10 xs:mt-[0%] xs:ml-[-20%] 4xl:mt-[3%] sa:ml-[-20%]">
            <h1 className="text-sky-400 lg:text-2xl lg:mt-[-7%] lg:ml-20 xs:mt-[155%] xs:ml-[-69%] xs:w-[200px] ip:mt-[-13%] ip:w-[300px] ip:ml-[20%]   ip:text-4xl 2xl:text-3xl sa:mt-[190%] xl:ml-[20%] xl:w-[300px] xl:mt-[-12%] 2xl:mt-[-10%] xs:text-3xl sa:text-2xl sa:mb-[10%] sa:ml-[-80%] ca:ml-[-68%] 4xl:text-5xl 4xl:w-[500px]">
              Get in Touch
            </h1>
            <MdOutlinePhoneInTalk className="lg:ml-20 lg:mt-[-6%] xs:mt-5 ip:ml-[19%] ip:mb-2 sa:mb-[-8%] sa:ml-[-80%] xs:ml-[-67%] 4xl:mt-[-5%] xl:ml-[18%]" />
            <p className="lg:ml-28 lg:mt-[-7%] xs:ml-[-50%] xs:px-0 xs:mt-[-11%] ip:ml-[30%] ip:mb-[-3%] 2xl:mt-[-10%] sa:mt-[-11%] sa:mb-[14%] sa:ml-[-65%] 4xl:ml-[23%] 4xl:w-[300px] 4xl:mt-[-7%] xl:ml-[30%] lg:mb-[4%]">
              +91 75300 01564
            </p>
            <MdOutlineLocalPostOffice className="lg:ml-20 lg:mt-8 xs:mt-8 ip:mb-2 ip:ml-[19%] 2xl:mt-10 sa:mb-[-8%] sa:ml-[-80%] xs:ml-[-67%] xl:ml-[18%]" />
            <p className="lg:ml-28 lg:mt-[-7%] xs:ml-[-50%] xs:mt-[-11%] ip:ml-[31%] 2xl:mt-[-10%] 4xl:ml-[23%] 4xl:mt-[-7%] sa:ml-[-65%] sa:mt-[-11%] xl:ml-[30%]">
              contact@zta.digital
            </p>
            <button className="lg:px-8 lg:py-2 lg:ml-20 lg:mt-20 rounded-full hover:text-white border-solid border-2 border-indigo-600 outline outline-offset-0 xs:mt-10 sa:mt-10 sa:relative sa:ml-[-80%] ip:p-4 ip:ml-[19%] 2xl:mt-[10%] xs:mb-10 xs:ml-[-50%] xs:p-4 sa:p-4 sa:mb-10 4xl:p-5 xl:ml-[20%]">
              <Link to="/register">Start Free Trial</Link>
            </button>        
          </div>
          <div className="absolute lg:ml-[88%] lg:w-10 lg:mt-[15%] 2xl:mt-[14%] 2xl:w-16 4xl:mt-[14%] xl:w-10 xl:ml-[93%] xl:mt-[20%] sa:w-10 sa:mt-[120%] sa:ml-[80%] xs:mt-[110%] ip:mt-[27%] ip:ml-[88%] ip:w-16">
            <Backbutton />
            </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
