import React from 'react';
import {useState,useEffect} from 'react';
import upimg from "../assets/uparrow2.png";


function Backbutton() {
    const [backtoTop,setBacktotop]=useState(false)

    useEffect(()=>{
        window.addEventListener("scroll",()=>{
            if(window.scrollY > 100){
                setBacktotop(true)
            }else{
                setBacktotop(false)
            }
        })

    },[])

    const scrollUp =()=>{
        window.scrollTo({
            top: 10,
            behavior:"smooth"
        })
    }
  return (
    <div>
        {backtoTop && (
            <button className='rounded-full' onClick={scrollUp}>
                <img src={upimg} className="rounded-full"/>
            </button>
        )}
    </div>
  )
}

export default Backbutton