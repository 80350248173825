import React from "react";
import { MdOutlineLockClock, MdCheck, MdStorage } from "react-icons/md";

const Secure = () => {
  return (
    <div id="secure" className="bg-gray-100 relative w-full lg:h-[500px] ip:h-[520px] ip:w-[700px] ip:mt-[80%] ip:border-l-4 ip:border-indigo-500 ip:bg-white  h-[900px] md:mt-10 mt-5 lg:mt-[25%] lg:w-[1300px] lg:mx-auto lg:border-l-4 lg:bg-white border-indigo-500 xs:h-[840px] xs:mt-[254%] sa:mt-[238%] sa:ml-5 sa:w-[320px] sa:rounded-xl sa:h-[850px] xl:mt-[51%] 2xl:mt-[-2%] xl:w-[1200px] xl:ml-[6%] xl:h-[520px] xl:border-l-4 xl:border-indigo-500 xl:bg-white xl:rounded-xl 2xl:w-[1500px] xs:w-[350px] xs:ml-[7%] xs:rounded-xl 4xl:w-[1700px] 4xl:h-[650px] 4xl:mt-[-6%] ba:mt-[232%] ba:ml-[7%] ba:h-[820px] ca:h-[850px]">
      <div className="text-xl 3xl:text-7xl lg:text-3xl text-center lg:w-full font-sans text-indigo-900 ip:text-3xl xs:text-2xl lg:mb-[-4%] ">
        <h1 className="font-sans mb-20 tracking-tighter lg:p-8 sa:text-2xl ip:text-4xl ip:p-8 2xl:text-4xl sa:p-4 xs:text-3xl 4xl:text-5xl">
          Secure Office Management Software using latest technologies
        </h1>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 ip:grid-cols-2 gap-5 px-5 md:px-20 text-center font-sans -mt-10 md:mt-10 mr-10 ip:mt-[-10%]">
        <div className="border w-[290px] h-[150px] lg:w-[450px] 3xl:w-[1000px] 3xl:h-[400px] lg:h-[150px] lg:ml-[10%] lg:mt-[15%] ip:w-[340px] ip:ml-[-30%] rounded-xl shadow-xl flex items-center md:ml-72 p-3 bg-cyan-300 3xl:text-6xl xs:ml-[4%] xs:text-xl sa:mt-[-10%] sa:ml-[-3%] 2xl:text-2xl xl:text-xl xl:ml-8 xl:w-[400px] xl:mt-20 ip:text-2xl 2xl:w-[500px] 2xl:ml-20 4xl:w-[700px] 4xl:ml-[2%] 4xl:text-4xl 4xl:h-[200px] ba:mt-[-15%] ba:ml-[-2%] ca:mt-[-5%]" data-aos="fade-up" >
          <MdOutlineLockClock className="flex bg-blue-500 rounded-full bg-auto md:text-4xl fill text-white ml-8 3xl:text-8xl 3xl:p-4 xs:text-3xl sa:text-2xl 4xl:text-5xl" />
          <div>
            <p className="text-red-600 mr-2">2048 Bit Encryption</p>
            <p className="ml-2 md:ml-10 text-slate-600 ip:ml-0 lg:ml-8 xl:ml-10">
              Banking grade encryption
            </p>
          </div>
        </div>
        <div className="border w-[290px] h-[150px] md:w-[350px] md:h-[150px] rounded-xl shadow-xl flex items-center gap-2 p-3 bg-cyan-300 3xl:w-[1000px] 3xl:h-[400px] 3xl:text-6xl lg:w-[450px] lg:mt-[15%] lg:ml-[10%] ip:ml-3 ip:mt-[-5%] ip:w-[340px] 2xl:text-2xl xl:text-xl ip:text-2xl xl:mt-20 xl:ml-24 xl:w-[400px] 2xl:w-[500px] xs:ml-[4%] xs:text-xl sa:ml-[-3%] 4xl:w-[700px] 4xl:text-4xl 4xl:h-[200px] ba:ml-[-2%]" data-aos="fade-up">
          <MdOutlineLockClock className="flex bg-blue-500 rounded-full bg-auto md:text-4xl fill text-white ml-8 3xl:text-8xl 3xl:p-4 xs:text-5xl xs:mr-5 sa:text-4xl 4xl:text-5xl ip:text-5xl ip:w-[15%]" />
          <div>
            <p className="text-slate-600 text-left md:ml-5 mr-5">
              Your Data is most secure with key lock protection
            </p>
          </div>
        </div>
        <div className=" border w-[290px] h-[150px] md:w-[350px] md:h-[150px] rounded-xl shadow-xl flex items-center md:ml-72 p-3 bg-amber-200 3xl:w-[1000px] 3xl:h-[400px] 3xl:text-6xl lg:ml-[10%] ip:w-[340px] ip:ml-[-30%] lg:w-[450px] sa:ml-[-3%] 2xl:text-2xl xl:text-xl ip:text-2xl  xl:ml-8 xl:w-[400px] 2xl:w-[500px] 2xl:ml-20 xs:ml-[4%] xs:text-xl 4xl:w-[700px] 4xl:ml-[2%] 4xl:text-4xl 4xl:h-[200px] ba:ml-[-2%]" data-aos="fade-right">
          <MdStorage className="flex bg-green-400 rounded-full bg-auto fill text-white w-2em text-2xl 3xl:text-8xl md:text-4xl 3xl:p-4 xs:text-3xl sa:text-2xl 4xl:text-5xl sa:ml-8" />
          <div>
            <p className="mr-20 md:mr-14 text-red-600 xs:mr-2 4xl:ml-[-10%]">AWS Aurora</p>
            <p className="ml-2 md:ml-9 text-slate-600 ip:ml-0 lg:ml-8 xl:ml-10">
              The fastest SQL database
            </p>
          </div>
        </div>
        <div className=" border w-[290px] h-[150px] md:w-[350px] md:h-[150px] rounded-xl shadow-xl flex items-center gap-2 p-3 bg-amber-200 3xl:w-[1000px] 3xl:h-[400px] 3xl:text-6xl lg:w-[450px] lg:ml-[10%] ip:ml-3 ip:mt-[0%] ip:w-[340px] sa:ml-[-3%] 2xl:text-2xl xl:text-xl ip:text-2xl xl:ml-24 xl:w-[400px] 2xl:w-[500px] xs:ml-[4%] xs:text-xl 4xl:w-[700px] 4xl:text-4xl 4xl:h-[200px] ba:ml-[-2%]" data-aos="fade-left">
          <MdStorage className="flex bg-green-400 rounded-full bg-auto fill text-white text-xl ml-8 3xl:text-8xl md:text-4xl 3xl:p-4 ip:text-4xl xs:text-3xl sa:text-2xl 4xl:text-5xl" />
          <div>
            <p className="mr-2 md:ml-5 text-slate-600">
              Secure Storage with AWS
            </p>
          </div>
        </div>
      </div>
      <div className="lg:border-r-4 lg:bg-white border-indigo-500 lg:h-[500px] text-xl md:text-3xl text-center mt-20 w-full font-sans text-indigo-900 3xl:text-7xl lg:mt-[8%] lg:w-[1300px] lg:mx-auto sa:text-2xl 2xl:text-4xl ip:text-4xl ip:h-[570px] ip:w-[700px] ip:border-r-4 ip:border-indigo-500 ip:bg-white xl:w-[1200px] xl:mt-[7%] xl:border-r-4 xl:border-indigo-500 xl:bg-white xl:rounded-xl xl:h-[650px] 2xl:w-[1500px] 2xl:h-[500px] xs:bg-sky-50 xs:rounded-xl xs:mt-[22%] xs:w-[350px] xs:h-[1100px] xs:text-3xl sa:bg-sky-50 sa:rounded-xl sa:mt-20 sa:w-[320px] sa:h-[850px] 4xl:w-[1700px] 4xl:text-5xl 4xl:h-[600px] ca:mt-[15%] ba:mt-[20%]">
        <h1 className="font-sans tracking-tighter lg:p-8 sa:p-4 xl:p-8">
          How ClearTicks helps in your Practice Management?
        </h1>
        <div className="grid md:grid-cols-2 gap-1 md:px-2 lg:ml-[25%] ip:ml-10 md:mt-10 mx-5 text-center font-sans w-[305px] lg:w-[1100px] 3xl:w-[1200px] xl:ml-60">
          <div className="border py-8 rounded-xl shadow-xl mr-1 3xl:w-[1200px] lg:m-[-45%] lg:w-[500px] lg:h-[320px] lg:text-xl lg:bg-gradient-to-r from-cyan-500 to-blue-500 xs:ml-[1%] xs:text-2xl xs:w-[100%] xs:bg-teal-500 xs:shadow-xl ip:w-[350px] ip:h-[400px] ip:ml-[-45%] ip:mt-[-5%] sa:text-base sa:mt-10 sa:bg-teal-500 lg:mt-0 2xl:ml-[-42%] xl:ml-[-110%] xl:mt-[5%] xl:w-[450px] 2xl:mt-[-2%] sa:ml-[0%] sa:w-[280px] 4xl:w-[700px] 4xl:ml-[-62%] 4xl:h-[430px] "data-aos="zoom-in">
            <p className="text-2xl 3xl:text-6xl md:text-3xl font-bold text-white font-sans xs:text-2xl 4xl:text-5xl">
              Team Management
            </p>
            <ul>
              <li className="text-white  flex font-sans text-left 3xl:text-5xl ip:text-2xl">
                <MdCheck
                  size="1.5rem"
                  className="text-white 3xl:text-6xl xs:w-10"
                />
                <p className="md:ml-2 lg:text-xl 4xl:text-4xl">
                  Track which task is assigned to which user
                </p>
              </li>
              <li className="text-white  flex font-sans text-left 3xl:text-5xl ip:text-2xl">
                <MdCheck
                  size="1.5rem"
                  className="text-white 3xl:text-6xl xs:w-10"
                />
                <p className="md:ml-2 lg:text-xl 4xl:text-4xl">
                  Calculate how many hours are spend on same type of tasks
                </p>
              </li>
              <li className="text-white  flex font-sans text-left 3xl:text-5xl ip:text-2xl">
                <MdCheck
                  size="1.5rem"
                  className="text-white 3xl:text-6xl xs:w-10"
                />
                <p className="md:ml-2 lg:text-xl 4xl:text-4xl">
                  Track which task is assigned to which user
                </p>
              </li>
            </ul>
          </div>
          <div className="border md:py-8 mt-5 md:mt-0 rounded-xl shadow-xl lg:w-[300px]w-[205px] 3xl:w-[1200px] 3xl:ml-[120%]  ip:w-[350px] ip:ml-[100%] ip:h-[400px] ip:mt-[-5%] lg:w-[500px] lg:h-[320px] lg:ml-[-33%] lg:text-xl lg:mt-[0%] lg:bg-gradient-to-r from-cyan-500 to-blue-500 xs:ml-[1%] xs:text-2xl xs:w-[100%] xs:bg-teal-500 xs:shadow-xl sa:bg-teal-500 sa:text-base 2xl:ml-[-20%] xl:ml-[180%] xl:mt-[2%] xl:w-[450px] 2xl:mt-[-2%] sa:ml-[0%] sa:w-[280px]  4xl:w-[700px] 4xl:ml-[-10%] 4xl:h-[430px]" data-aos="zoom-in">
            <p className="text-xl 3xl:text-6xl lg:text-3xl font-bold text-white font-sans mt-0 ip:text-3xl sa:mt-10 ip:mt-0 xs:text-2xl sa:text-2xl 4xl:text-5xl">
              Client Management
            </p>
            <ul>
              <li className="text-white  flex font-sans text-left 3xl:text-5xl ip:text-2xl">
                <MdCheck
                  size="1.5rem"
                  className="  text-white 3xl:text-6xl xs:w-10"
                />
                <p className="md:ml-2 lg:text-xl 4xl:text-4xl">
                  Group clients into client group for ease of tracking
                </p>
              </li>
              <li className="text-white flex font-sans text-left 3xl:text-5xl ip:text-2xl">
                <MdCheck
                  size="1.5rem"
                  className="  text-white 3xl:text-6xl xs:w-10"
                />
                <p className="md:ml-2 lg:text-xl 4xl:text-4xl">
                  Rich insights on which client group provides best
                  profitability
                </p>
              </li>
              <li className="text-white  flex font-sans text-left 3xl:text-5xl ip:text-2xl">
                <MdCheck
                  size="1.5rem"
                  className="text-white 3xl:text-6xl xs:w-10"
                />
                <p className="md:ml-2 lg:text-xl 4xl:text-4xl">
                  Built in Contact and Password management
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Secure;
