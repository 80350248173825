import React from "react";
import { MdOutlinePhoneInTalk, MdOutlineLocalPostOffice } from "react-icons/md";

const Topbar = () => {
  return (
    <div>
      <div className="w-full md:w-screen h-[40px] md:h-[40px] 3xl:h-[90px] 4xl:h-[100px] xs:h-[80px] sa:h-[80px] bg-gray-100 drop-shadow-sm text-left text-xl xl:w-full xs:text-center sa:w-full sa:text-center ip:h-[70px] lg:h-[60px]">
        <div className="px-2 flex justify-between items-center md:w-full h-full ">
          <div className="flex items-center md:w-full font-xs font-sans grid-col-2">
            <ul className="md:flex font-sans text-black-400 font-light md:text-base 3xl:text-4xl lg:text-base text-slate-600 tracking-wide xs:mt-16 xs:ml-0 lg:mt-2 lg:ml-0 xs:leading-none sa:mt-20 2xl:text-xl xl:text-xl xl:mt-32 ip:text-2xl 4xl:text-3xl ip:mt-32">
              <li>
                <MdOutlinePhoneInTalk className="text-blue-400 absolute 3xl:ml-5 md:mt-1 sa:mt-16 " />
                <p className="flex md:ml-8 3xl:ml-20 xs:ml-8 xs:leading-3 lg:leading-none lg:ml-6 lg:mt-1 ip:mt-2 sa:ml-8 sa:mt-16 4xl:ml-10">
                  +91 75300 01564
                </p>
              </li>
              <li>
                <MdOutlineLocalPostOffice className="text-blue-400 absolute md:mt-1 3xl:ml-5 xs:mt-[-1] sa:mt-[-7%]" />
                <p className="flex md:ml-8 3xl:ml-20 xs:mb-32 lg:ml-6 lg:mb-0 xs:ml-8 sa:ml-8 sa:mb-32 sa:mt-[-13%] lg:mt-[0%] ip:mt-0 4xl:ml-10">
                  contact@zta.digital
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
