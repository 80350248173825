import React, { useState } from "react";
import { Menu, Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Records from "./Records.json";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  const handleClose = () => setNav(!nav);

  return (
    <div>
      <div className="w-full h-[80px] 3xl:h-[150px] bg-slate-50 drop-shadow-sm 4xl:h-[130px]">
        <div className="px-2 flex justify-between items-center w-full h-full">
          <div className="flex items-center w-full font-xs font-sans">
            <h1 className="xs:text-3xl text-2xl 3xl:text-9xl ip:text-5xl font-sans font-bold mr-7 lg:text-4xl tracking-tighter text-red-600 2xl:ml-5 xl:ml-5 4xl:text-6xl">
              {Records.map((Records) => {
                return <div>{Records.title}</div>;
              })}
            </h1>
            <ul className="hidden md:flex font-sans text-black-400 font-light  text-slate-600 tracking-wide 3xl:text-5xl 2xl:text-xl xl:text-xl ip:text-2xl ip:ml-[-3%] lg:ml-20 xl:ml-10 4xl:text-4xl">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
            <a href="/#secure">Security</a>
              </li>
              <li>
                <Link to="/inquire">Inquire</Link>
              </li>
            </ul>
          </div>
          <div className="hidden md:flex">
          <button class="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-600 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800  w-[250px] py-3 px-3 font-sans hover:bg-sky-700 3xl:w-[300px] 3xl:h-[80px] 3xl:text-5xl 3xl:mr-5 ip:w-[150px] 2xl:mr-10 xl:mr-20 lg:ml-[15%] lg:text-2xl lg:w-[260px] ip:ml-[-14%] ip:text-base xl:w-[200px] xl:ml-10 4xl:text-4xl 4xl:w-[290px] 4xl:ml-[-5%] ">
           <span class="relative px-2 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
           <Link to="/signup">Start Free Trial</Link>
          </span>
      </button>
          </div>
          <div className="md:hidden mr-4 xs:ml-32" onClick={handleClick}>
            {!nav ? <Menu className="w-5" /> : <Close className="w-5" />}
          </div>
        </div>
        <ul className={!nav ? "hidden" : "absolute bg-zinc-200 w-full px-8 "}>
          <li className="border-b-2 border-zinc-300 w-full font-sans text-slate-800">
            <Link onClick={handleClose} to="/">
              Home
            </Link>
          </li>
          <li className="border-b-2 border-zinc-300 w-full font-sans text-slate-800">
            <Link onClick={handleClose} to="/pricing">
              Pricing
            </Link>
          </li>
          <li className="border-b-2 border-zinc-300 w-full font-sans text-slate-800">
            <a href="/#secure" onClick={handleClose}>
              Security
            </a>
          </li>
          <li className="border-b-2 border-zinc-300 w-full font-sans text-slate-800">
            <Link onClick={handleClose} to="/inquire">
              Inquire
            </Link>
          </li>
          <div className="flex flex-col my-4 ">
            <button className="px-8 py-3 font-sans">
              <Link onClick={handleClose} to="/signup">
                Sign Up
              </Link>
            </button>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
