import { Diversity1Outlined } from "@mui/icons-material";
import React from "react";
import Hero from "../components/Hero";
import Footer from "./Footer";
import Modules from "./Modules";
import Secure from "./Secure";
import Taskorganizer from "./Taskorganizer";

const Home = () => {
  return (
    <div>
      <Hero />
      <Modules />
      <Secure />
      <Taskorganizer />
      <Footer />
    </div>
  );
};

export default Home;
